.App-Form{
  background: linear-gradient(147deg, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 57%, rgb(237, 29, 35, 0.21) 50%);
  min-height: 100vh;
}
.form-container{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid #8d8d8d;
  padding: 10px;
}
.insurance-container{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-top: 1px solid #8d8d8d;
  border-bottom: 1px solid #8d8d8d;
  margin-top: 10px;
  margin-bottom: 10px;
}